* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --main: #FFB011;
  --text: #2A2C39;
  --accent1: #FF4400;
  --accent2: #355070;
  --white: #FAFDF6;
}

body {
  margin: 0;
  font-family: 'Helvetica Neue', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--main);
  color: var(--text);
}

main {
  padding: 20px;
  max-width: 1200px;
}

h1 {
  font-weight: 300;
  font-size: 64px;
  color: var(--white);
}

/* header */

header {
  /* background: var(--accent1); */
  padding: 20px 40px;
  width: 100%;
  font-family: 'Averia Serif Libre', serif;
  font-weight: 300;
}

/* end of header */

/* nav */

nav li {
  list-style-type: none;
  color: var(--white);
  opacity: 80%;
  padding: 10px;
}

nav li:hover {
  cursor: pointer;
  opacity: 100%;
  transition: opacity 0.3s ease-in-out;
}

.navActive {
  opacity: 100%;
}

/* end of nav */

/* main */


/* end of main */

/* about */

.about {
  padding: 10% 0;
  max-width: 1200px;
}

.about-text {
  font-size: 28px;
}

/* end of about */


/* portfolio */


.portfolio-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-auto-flow: dense;
  width: 100%;
  gap: 10px;
  padding-bottom: 80px;
}

.portfolio-img-container {
  background: var(--accent2);
  position: relative;
  border-radius: 5px;
  display: block;
  max-height: 200px;
}

.featured {
  grid-column: 1 / span 2;
  grid-row: 1 / span 2;
  position: relative;
  border-radius: 5px;
  display: block;
  min-height: 200px;
}

.portfolio-img-title {
  text-transform: uppercase;
  font-size: smaller;
  color: var(--white);
  font-weight: bold;
}

.screen {
  position: absolute;
  background: rgba(42, 44, 57, 0.7);
  z-index: 998;
  height: 100%;
  width: 100%;
  margin: 0;
  border-radius: 5px;
}

.link-container {
  z-index: 997;
  background: rgba(42, 44, 57, 0.8);
  padding: 5px;
  position: absolute;
  width: 100%;
  visibility: hidden;
  border-radius: 0;
  bottom: 40%;
}

.portfolio-img-container:hover > .link-container, .featured:hover > .link-container {
  visibility: visible;
  z-index: 1000;
}

.portfolio-img-container:hover > .screen, .featured:hover > .screen {
  opacity: 0;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
}

.portfolio-link {
  color: var(--white);
  opacity: 70%;
  font-weight: bold;
  text-decoration: none;
  padding: 10px;
}

.portfolio-link:hover {
  color: var(--white);
  opacity: 100%;
  text-decoration: none;
  transition: opacity 0.3s ease-in-out;
}

.portfolio-link:visited, .portfolio-link:active, .portfolio-link:link  {
  color: var(--white);
  text-decoration: none;
  font-weight: bold;
  text-decoration: none;
  padding: 10px;
}

.portfolio-img {
  background-image: linear-gradient(#355070, #355070);
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center top;
  border-radius: 5px;
}

.project-footer {
  background: var(--accent2);
  color: var(--white);
  position: absolute;
  text-align: center;
  width: 100%;
  height: 30px;
  padding: 7px;
  border-radius: 0 0 5px 5px;
  z-index: 1001;
  bottom: 0;
}

.project-footer svg {
  margin: 0 5px;
}

/* end of portfolio */

/* footer */

footer {
  background: var(--accent2);
  position: fixed;
  z-index: 1005;
  bottom: 0;
  width: 100%;
}

footer ul {
  list-style-type: none;
}

footer li {
  padding: 20px;
}

footer a {
  font-size: 24px;
  color: var(--white);
  opacity: 80%;
}

footer a:hover {
  opacity: 100%;
  transition: opacity 0.3s ease-in-out;
}

/* end of footer */

/* utility classes */

.flex {
  display: flex;
}

.column {
  flex-flow: column wrap;
}

.row {
  flex-flow: row wrap;
}

.align-center {
  align-items: center;
}

.align-content-center {
  align-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

/* end of utility classes */

/* media queries */
@media only screen and (max-width: 576px) {
  .sm-justify-center {
    justify-content: center;
  }
  h1 {
    font-size: 48px;
  }
  .about {
    padding: 0;
  }
  .about-text {
    font-size: 24px;
  }
  .portfolio-container {
    grid-template-columns: 1fr 1fr;
  }
}